body {
  font-family: 'Mitr', sans-serif !important;
  background-color: #22272C;
}

.bg-body {
  background-color: #22272C;
}

.bg-secondary {
  background-color: #2E343A;
}

